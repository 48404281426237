import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0rem'
        },
        [theme.breakpoints.up("md")]: {
            padding: '2rem 0rem'
        }
    },
    textfield: {
        width: '100%'
    },
    button: {
        width: '10rem',
        float: 'right'
    },
}));

const ContactForm = () => {
    const classes = useStyles();

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12}>
                    <TextField id="Name" label="Name" variant="filled" className={classes.textfield} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="Email" label="Email" variant="filled" className={classes.textfield} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="Phone" label="Phone" variant="filled" className={classes.textfield} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="Message" label="Message" variant="filled" className={classes.textfield} multiline rows={4}/>
                </Grid>
                <Grid item xs={12}>
                    <Button className={classes.button}>Send</Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default ContactForm;