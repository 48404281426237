import React from "react";
import { Container } from "@material-ui/core";
import Layout from "../components/Layout/layout";
import Squares from '../components/Squares/Squares'
import PageInfoSection from '../components/PageInfoSection/PageInfoSection';
import ContactForm from '../components/ContactForm/ContactForm';

const ContactPage = () => (
  <Layout>
    <Squares>
    <Container>
      <PageInfoSection
        title="get in"
        blueTitle="touch"
        text="Successful businesses have strong online presences. As our digital footprint becomes wider, it is important to stay ahead of the curve. Having a user-friendly interface that is easy to interact with is essential in today’s e-commerce market. Set your business apart with a state of the art product by Webfluent today.">
        <ContactForm />
      </PageInfoSection>
    </Container>
    </Squares>
  </Layout>
)

export default ContactPage