import React from "react"
import { Grid, Typography, Button, ListItem, ListItemText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paddingY: {
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0rem'
    },
    [theme.breakpoints.up("md")]: {
      padding: '2rem 0rem'
    }
  },
  paragrapgh: {
    lineHeight: "2",
  },
  buttonLeft: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      margin: `${theme.spacing(1)}px 8px`
    }
  },
  buttonRight: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      margin: `${theme.spacing(1)}px 8px`
    }
  }
}))

const PageInfoSection = ({ title, blueTitle, text, children, list }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <div className={classes.paddingY}>
          <Typography style={{ fontFamily: 'Cabin', fontStyle: 'italic' }} variant="h3">{title}</Typography>
          <Typography
            variant="h3"
            gutterBottom
            style={{ color: "rgb(37,137,124)", fontFamily: 'Cabin', fontStyle: 'italic' }}
          >
            {blueTitle}
          </Typography>
        </div>
        <Typography className={classes.paragraph} variant="body2" gutterBottom>
          {text}
        </Typography>
        {list && (

          list.map(i => (
            <ListItem >
              <ListItemText>
                <Typography variant='body2'>
                  {i}
                </Typography>
              </ListItemText>
            </ListItem>
          ))

        )}
        <br></br>
        <div className={classes.paddingY}>
          <Button className={classes.buttonLeft}>Contact Us</Button>
          <Button className={classes.buttonRight} style={{ background: "#fff", color: "#000000CC" }}>
            View Our Work
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
    </Grid>
  )
}

export default PageInfoSection
