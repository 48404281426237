import React from 'react'
import SquaresImg from "../../assets/images/squares.png"
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
const useStyles = makeStyles(theme => ({
    imgSection: {
        position: "relative",
        overflow: 'hidden'
    },
    squares: {
        position: 'absolute',
        zIndex: '-10',
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            width: '45%'
        }
    },
    squaresTop: {
        left: -20,
        [theme.breakpoints.down('sm')]: {
            top: '-2.5%'
        }
    },
    squaresBottom: {
        right: "-5%",
        bottom: "-10%",
        [theme.breakpoints.down('sm')]: {
            bottom: '-5%'
        }
    }


}))

const Squares = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.imgSection}>
            <img className={clsx(classes.squares, classes.squaresTop)} id="squares-top" alt='squares-top' src={SquaresImg} />
            {children}
            <img className={clsx(classes.squares, classes.squaresBottom)} id="squares-bottom" alt='squares-bottom' src={SquaresImg} />
        </div>

    )
}

export default Squares
